export default {
	login: '/training-api/v1/Sessions', //登录
	getChinaRegions: '/governance-api/v1/ChinaRegions.json', //获取省市区数据
	modifyPasswords: '/training-api/v1/Passwords/~.json', //修改密码
	getTrainingSubject: '/training-api/v1/TrainingSubjects', //获取培训科目
	getAttachmentGroups: '/common-api/v1/AttachmentGroups.json', //获取附件组
	getDictionaryItems: '/common-api/v1/DictionaryItems', //获取字典项
	getDictionaries: '/common-api/v1/Dictionaries', //获取字典
	getCourses: '/training-api/v1/Courses', //获取课程列表
	getOrders: '/training-api/v1/Orders.json', //获取订单列表
	getFamousTeachers: '/training-api/v1/FamousTeachers', //获取名师列表
	getFamousTeacherLectures: '/training-api/v1/FamousTeacherLectures', //获取讲堂列表
	getLectureTrains: '/training-api/v1/LectureTrains.json', //获取讲堂下的培训列表
	getLectureLessons: '/training-api/v1/LectureLessons.json', //获取讲堂下的课程列表
	delAttachments: '/common-api/v1/Attachments', //附件删除
	getLecturePaymentRights: '/training-api/v1/LecturePaymentRights.json', //获取培训列表的报名名单
};
