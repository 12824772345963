import Vue from 'vue';
import VueRouter from 'vue-router';

// 解决 `element ui` 导航栏重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 安装 VueRouter 插件
Vue.use(VueRouter);

/* Layout */
import Layout from '@/layout/index';
export const constantRoutes = [
	{
		path: '/login',
		component: () => import('@/views/login'),
		hidden: true,
		meta: {
			title: '登录',
		},
	},

	{
		path: '/404',
		component: () => import('@/views/error/404'),
		hidden: true,
		meta: {
			title: '404',
		},
	},
	{
		path: '/',
		component: Layout,
		redirect: '/course',
	},
	{
		path: '/account',
		component: Layout,
		redirect: '/account/personalInfo',
		name: 'account',
		meta: {
			title: '账号设置',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/account/personalInfo',
				name: 'personalInfo',
				component: () => import('@/views/account/personalInfo'),
				meta: {
					title: '个人信息',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/course',
		component: Layout,
		redirect: '/course/subject',
		name: 'course',
		meta: {
			title: '课程管理',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/course/subject',
				name: 'subject',
				component: () => import('@/views/course/subject'),
				meta: {
					title: '科目管理',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/course/textbook',
				name: 'textbook',
				component: () => import('@/views/course/textbook'),
				meta: {
					title: '课程与教材管理',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/course/textbook/uploadMaterial',
				name: 'uploadMaterial',
				component: () => import('@/views/course/textbook/uploadMaterial'),
				meta: {
					title: '上传资料',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/course/statistics',
				name: 'statistics',
				component: () => import('@/views/course/statistics'),
				meta: {
					title: '购买统计',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/traningManagement',
		component: Layout,
		name: 'traningManagement',
		redirect: '/traningManagement/traning',
		children: [
			{
				path: '/traningManagement/traning',
				name: 'index',
				component: () => import('@/views/traningManagement/index'),
				meta: {
					title: '学习培训',
					roles: ['admin', 'common'],
				},
			},
		],
	},
	{
		path: '/teacherHall',
		component: Layout,
		redirect: '/teacherHall/classManger',
		name: 'teacherHall',
		meta: {
			title: '名师讲堂',
			roles: ['admin', 'common'],
		},
		children: [
			{
				path: '/teacherHall/classManger',
				name: 'classManger',
				component: () => import('@/views/teacherHall/classManger'),
				meta: {
					title: '类别管理',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/teacherHall/teacherManager',
				name: 'teacherManager',
				component: () => import('@/views/teacherHall/teacherManager'),
				meta: {
					title: '名师管理',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/teacherHall/hallManager',
				name: 'hallManager',
				component: () => import('@/views/teacherHall/hallManager'),
				meta: {
					title: '讲堂管理',
					roles: ['admin', 'common'],
				},
			},
			{
				path: '/teacherHall/hallManager/uploadMaterial',
				name: 'uploadCourse',
				component: () => import('@/views/teacherHall/hallManager/uploadMaterial'),
				meta: {
					title: '添加培训课程',
					roles: ['admin', 'common'],
				},
			},
		],
	},

	{
		path: '*',
		redirect: '/404',
		hidden: true,
	},
];
const createRouter = () =>
	new VueRouter({
		// mode: 'history', // require service support
		scrollBehavior: () => ({ y: 0 }),
		routes: constantRoutes,
	});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter();
	router.matcher = newRouter.matcher; // reset router
}

export default router;
